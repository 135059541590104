import {
    Call,
    CallWithFormData,
    CallWithFormDataFile,
    cleanEmpty,
    downloadBlob,
    objectToFormData,
} from "../../../helpers/fetch";
import { types } from "../../types";
import { startUILoading, stopUILoading } from "../administrador/ui/ui";
import { errorHandler } from "../../../helpers/errorHandler";
import Swal from "sweetalert2";
import { setCalendar, setCalendars } from "../operators/AcademicSchedules";
import { deleteConfirmModal } from "../../../helpers/DeleteValidate";
import { setResponse } from "../../slices/components/SubjectsByStudentApp";
import { values } from "lodash";

export const startShowStudents = ({
    page = 1,
    query = "",
    number_rows = 25,
    status = [],
    degree = [],
    group = [],
    except = [],
    curricula = [],
    country = null,
    // cycle_id = null,
    cycle_ids = [],
    all = false,
    english_level = [],
    adviser_ids = [],
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("page", page);
        params.set("q", query);
        params.set("per_page", number_rows);
        // params.set("all", all);

        if (status) {
            params.set(
                "status",
                status.map((c) => c.value)
            );
        }
        if (degree) {
            params.set(
                "degree",
                degree.map((c) => c.value)
            );
        }
        if (curricula) {
            params.set(
                "curricula",
                curricula.map((c) => c.value)
            );
        }
        if (group) {
            params.set(
                "group",
                group.map((c) => c.value)
            );
        }
        if (except) {
            params.set(
                "except",
                except.map((s) => s.id)
            );
        }

        if (cycle_ids) {
            params.set(
                "cycle_ids",
                cycle_ids.map((s) => s.value)
            );
        }
        if (english_level) {
            params.set(
                "english_level",
                english_level.map((s) => s.value)
            );
        }
        if (adviser_ids) {
            params.set(
                "adviser_ids",
                adviser_ids.map((adviser) => adviser?.value)
            );
        }

        if (country) {
            params.set("country", country);
        }

        // if (cycle_id !== "") {
        //     params.set("cycle_id", cycle_id);
        // }

        const resp = await Call("students", "GET", params.toString());

        if (resp.success) {
            dispatch(show(resp.success.data));
        }
        dispatch(stopUILoading());
    };
};

export const startDelete = (academicRecord, callback = () => {}) => {
    return async (dispatch) => {
        await deleteConfirmModal(async () => {
            dispatch(startUILoading());

            const resp = await Call(
                `operators/students/delete/${academicRecord.id}`,
                "DELETE"
            );
            if (resp.success) {
                Swal.fire({
                    title: "Éxito",
                    text: "Registro eliminado.",
                    toast: true,
                    position: "bottom-end",
                    icon: "success",
                });
                callback();
            } else {
                errorHandler(resp, setErrors);
            }

            dispatch(stopUILoading());
        });
    };
};

export const startShowStudentsOnly = ({
    page,
    query = "",
    number_rows,
    status = [],
    degree = [],
    group = [],
    except = [],
    english_level = [],
    cycle_id = null,
    withLeads = false,
    hasGroup = true,
    curricula = [],
    student_numbers = "",
    //*Se da el valor inicial de vacio para que por defecto traiga todos
    freshman = "",
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("page", page);
        params.set("q", query);
        params.set("per_page", number_rows);

        if (hasGroup === 1 || hasGroup === 0) {
            params.set("hasGroup", hasGroup);
        }

        if (freshman === 1 || freshman === 0) {
            params.set("freshman", freshman);
        }
        if (cycle_id) {
            params.set("cycle_id", cycle_id);
        }

        if (withLeads) {
            params.set("withLeads", withLeads);
        }

        if (status) {
            params.set(
                "status",
                status.map((c) => c.value)
            );
        }
        if (degree) {
            params.set(
                "degree",
                degree.map((c) => c.value)
            );
        }

        if (curricula) {
            params.set(
                "curricula",
                curricula.map((c) => c.value)
            );
        }
        if (group) {
            params.set(
                "group",
                group.map((c) => c.value)
            );
        }
        if (except) {
            params.set(
                "except",
                except.map((s) => s.id)
            );
        }
        if (english_level) {
            params.set(
                "english_level",
                english_level.map((s) => s.value)
            );
        }
        if (student_numbers) {
            params.set(
                "student_numbers",
                student_numbers.map((c) => c.value)
            );
        }

        const resp = await Call("students/only", "GET", params.toString());

        if (resp.success) {
            dispatch(showStudentOnly(resp.success.data));
        }
        dispatch(stopUILoading());
    };
};

export const startCreateStudent = (values, navigate, setErrors = null) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        values = cleanEmpty(values);

        let formData = objectToFormData(values);

        const resp = await CallWithFormData(
            "students/create",
            "POST",
            formData
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
            });

            let student = resp.success;

            dispatch(startStudentSetActive(student));
            dispatch(startStudentCloseForm());
            navigate(`/app/students/form/${student.student_number}`);
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};
export const startNewAcademicRecordWithOutStudent = (
    values,
    navigate,
    setErrors = null
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        values = cleanEmpty(values);

        let formData = objectToFormData(values);

        const resp = await CallWithFormData(
            "students/register/academic-record/without-student",
            "POST",
            formData
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
            });

            let student = resp.success;

            dispatch(startStudentSetActive(student));

            navigate(`/app/students/form/${student.student_number}`);
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startAddCycleToStudent = (
    values,
    academic_record,
    setErrors = null,
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        values = cleanEmpty(values);

        let formData = objectToFormData({
            ...values,
            academic_record_id: academic_record.id,
        });

        const resp = await CallWithFormData(
            "students/academic_record/cycle",
            "POST",
            formData
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
            });
            dispatch(startGetStudentById(academic_record.student_number));
            callback();
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startDeleteAcademicRecordCycle = (
    academicRecordCycle,
    studentInfo
) => {
    return async (dispatch) => {
        await deleteConfirmModal(async () => {
            dispatch(startUILoading());
            const resp = await Call(
                `students/academic_record/cycle/${academicRecordCycle.id}`,
                "DELETE"
            );

            if (resp.success) {
                dispatch(startGetStudentById(studentInfo.student_number));
                Swal.fire({
                    title: "Éxito",
                    text: "Eliminado correctamente.",
                    icon: "success",
                });
            } else {
                errorHandler(resp);
            }
            dispatch(stopUILoading());
        });
    };
};

export const setCycleView = (cycle_id, academic_record_id) => {
    return async (dispatch, getState) => {
        dispatch(startUILoading());

        const resp = await Call(`operators/cycles/cycle-view`, "POST", {
            academic_record_id: academic_record_id,
            cycle_id: cycle_id,
        });

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Modificación exitosa.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 1000,
            });
            dispatch(UpdateCycles(cycle_id));
        }

        dispatch(stopUILoading());
    };
};

export const startUpdateStudent = (values, setErrors = null) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        delete values.student.academic_records;
        delete values.student.federal_entity_birth;
        delete values.student.uri;

        const { income_average, english_level } = values;

        let formData = objectToFormData({
            ...values.student,
            income_average,
            english_level,
        });

        const resp = await CallWithFormData(
            `students/edit/${values.student_number}`,
            "POST",
            formData
        );

        if (resp.success) {
            dispatch(startStudentSetActive(resp.success));
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
            });
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startUpdateAvatar = (student_number, photo) => {
    return async (dispatch, getState) => {
        dispatch(startUILoading());

        let url = "students/update-profile";

        let formData = new FormData();
        formData.append("photo", photo);
        formData.append("student_number", student_number);

        const resp = await CallWithFormData(url, "POST", formData);
        dispatch(startStudentSetActive(resp.success));

        dispatch(stopUILoading());
    };
};

export const startGetStudentById = (student_number, navigate = null) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(`students/get/${student_number}`);
        if (resp.success) {
            dispatch(startStudentSetActive(resp.success));
        } else {
            if (navigate) {
                navigate(`/app/students`);
            }
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startGetStudentByIdWithoutNavigate = (student_number) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(`students/get/${student_number}`);
        if (resp.success) {
            dispatch(startStudentSetActive(resp.success));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startGetGroupsByStudentNumber = (student_number, cycle_id) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();
        params.append("student_number", student_number);
        params.append("cycle_id", cycle_id);

        const resp = await Call(
            `students/get/academic_groups`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            dispatch(setGroupsByStudent(resp.success));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startGenerateCredential = ({ student_number }) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(`students/generate/credential`, "POST", {
            student_number,
        });
        if (resp.success) {
            let url = resp.success.credential;
            let link = document.createElement("a");

            link.href = url;
            link.setAttribute("download", `${student_number}.png`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startOpenPDFPage = (values) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        let url = `/app/students/report/payments/${values.student_number}`;
        window.open(
            url,
            "Reporte pagos de estudiante",
            "directories=no, location=no, menubar=no, scrollbars=yes, statusbar=no, tittlebar=no, width=900, height=600"
        );
        dispatch(stopUILoading());
    };
};
export const startGenerateXML = (data) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const modality_id = document.getElementsByName("modality_id")[0];
        const modality = modality_id.options[modality_id.selectedIndex].text;

        const funSocialService_id = document.getElementsByName(
            "funSocialService_id"
        )[0];
        const funSocialService =
            funSocialService_id.options[funSocialService_id.selectedIndex].text;

        const recognition_id = document.getElementsByName("recognition_id")[0];
        const recognition =
            recognition_id.options[recognition_id.selectedIndex].text;

        const expedition_federal_entity_id = document.getElementsByName(
            "expedition_federal_entity_id"
        )[0];
        const expedition_federal_entity =
            expedition_federal_entity_id.options[
                expedition_federal_entity_id.selectedIndex
            ].text;

        const history_federal_entity_id = document.getElementsByName(
            "history_federal_entity_id"
        )[0];
        const history_federal_entity =
            history_federal_entity_id.options[
                history_federal_entity_id.selectedIndex
            ].text;

        const historySchool_id =
            document.getElementsByName("historySchool_id")[0];
        const historySchool =
            historySchool_id.options[historySchool_id.selectedIndex].text;

        data = {
            ...data,
            modality,
            funSocialService,
            expedition_federal_entity,
            history_federal_entity,
            historySchool,
            recognition,
        };

        const formData = new FormData();

        formData.append("data", JSON.stringify(data));

        const headers = new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
        });

        const token = localStorage.getItem("token");
        if (token) {
            headers.append("Authorization", `Bearer ${token}`);
        }

        const options = {
            headers,
            method: "POST",
            body: formData,
        };

        const resp = await fetch(`/api/students/xml/generate/`, options);

        let url = window.URL.createObjectURL(await resp.blob());
        let link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", `${data.id}.xml`);
        document.body.appendChild(link);
        link.click();
        link.remove();

        dispatch(stopUILoading());
    };
};

export const startOpenCURPPage = (curp) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        try {
            // Solicitar permiso al usuario
            await navigator.permissions.query({ name: "clipboard-write" });

            // Si se concede el permiso, copiar al portapapeles y abrir la URL
            await navigator.clipboard.writeText(curp);

            let url = `https://www.gob.mx/curp`;
            window.open(
                url,
                "Validar CURP",
                "directories=no, location=no, menubar=no, scrollbars=yes, statusbar=no, titlebar=no, width=900, height=600"
            );
        } catch (error) {
            console.error(
                "Error al copiar al portapapeles o solicitar permiso:",
                error
            );
            alert(
                "Ocurrió un error al copiar al portapapeles o solicitar permiso."
            );
        }

        dispatch(stopUILoading());
    };
};

export const startShowStudentsSchedules = (cycle_id) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("cycle_id", cycle_id);

        const resp = await Call(`students/calendar`, "GET", params.toString());

        if (resp.success) {
            dispatch(
                setCalendars(resp.success.calendar, "STUDENT_SCHEDULE_SESSION")
            );
            dispatch(setResponse(resp.success));
        }
        dispatch(stopUILoading());
    };
};

// export const startShowStudentsSchedulesOperators = (
//     cycle_id,
//     student_number
// ) => {
//     return async (dispatch) => {
//         dispatch(startUILoading());

//         const params = new URLSearchParams();

//         params.set("cycle_id", cycle_id);

//         params.set("student_number", student_number);

//         const resp = await Call(
//             `operator/students/calendar`,
//             "GET",
//             params.toString()
//         );

//         if (resp.success) {
//             dispatch(setCalendar(resp.success.calendar));
//         }
//         dispatch(stopUILoading());
//     };
// };

export const startSaveDiscount = (data) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `students/account-status/discount`,
            "POST",
            data
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
        }

        dispatch(stopUILoading());
    };
};

export const startSendAccess = (data) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/students/send_access/${data.id}`,
            "POST"
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Credenciales enviadas exitosamente.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
        }

        dispatch(stopUILoading());
    };
};

export const startShowStudentsSocialServices = ({
    number_rows = 25,
    all = 0,
    academic_record = null,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("per_page", number_rows);
        params.set("all", all);

        const resp = await Call(
            `students/services/by/${academic_record}`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            dispatch(show(resp.success.data));
        }
        dispatch(stopUILoading());
    };
};

export const startShowPaidServices = ({
    number_rows = 25,
    all = 0,
    academic_record,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("per_page", number_rows);
        params.set("all", all);

        const resp = await Call(
            `students/services/by/${academic_record}`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            // dispatch(show(resp.success.data));
        }
        dispatch(stopUILoading());
    };
};

export const startDownloadReport = ({
    page,
    query = "",
    number_rows,
    status = [],
    degree = [],
    group = [],
    except = [],
    curricula = [],
    country = null,
    cycle_id = null,
    all = false,
    adviser_ids = [],
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("export", 1);
        params.set("page", page);
        params.set("q", query);
        params.set("per_page", number_rows);

        if (status) {
            params.set(
                "status",
                status.map((c) => c.value)
            );
        }
        if (degree) {
            params.set(
                "degree",
                degree.map((c) => c.value)
            );
        }
        if (curricula) {
            params.set(
                "curricula",
                curricula.map((c) => c.value)
            );
        }
        if (group) {
            params.set(
                "group",
                group.map((c) => c.value)
            );
        }
        if (except) {
            params.set(
                "except",
                except.map((s) => s.id)
            );
        }

        if (country) {
            params.set("country", country);
        }

        if (cycle_id) {
            params.set("cycle_id", cycle_id);
        }

        if (adviser_ids) {
            params.set(
                "adviser_ids",
                adviser_ids.map((adviser) => adviser?.value)
            );
        }

        const response = await CallWithFormDataFile(
            `students`,
            "GET",
            params.toString()
        );

        downloadBlob(response, `Reporte de estudiantes`);

        dispatch(stopUILoading());
    };
};

export const startInscriptionStudent = (values, setErrors = null, navigate) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        values = cleanEmpty(values);

        let formData = objectToFormData(values);

        const resp = await CallWithFormData(
            "students/solicitud-ingreso",
            "POST",
            formData
        );

        if (resp.success) {
            let student = resp.success;

            dispatch(startStudentSetActive(student));

            navigate(`/solicitud-ingreso-exitosa`);
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startAddFamily = (
    values,
    studentNumber,
    setErrors = null,
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const resp = await Call("students/family/create", "PUT", values);

        if (resp.success) {
            callback();
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
            });

            dispatch(startGetStudentByIdWithoutNavigate(studentNumber));
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startUpdateEnglishLevelByStudent = (
    values,
    academic_record_id,
    setErrors,
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const resp = await Call(
            `operators/students/update/english-level/${academic_record_id}`,
            "PUT",
            values
        );

        if (resp.success) {
            callback();
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startEditFamily = (
    values,
    studentNumber,
    setErrors = null,
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `students/family/edit/${values.id}`,
            "PATCH",
            values
        );

        if (resp.success) {
            callback();
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
            });

            dispatch(startGetStudentByIdWithoutNavigate(studentNumber));
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startDestroyFamily = (familyId, studentNumber) => {
    return async (dispatch) => {
        const result = await Swal.fire({
            title: "¿Estás seguro de eliminar?",
            icon: "warning",
            text: "No podrás revertir esta acción!",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Si, Eliminar!",
            confirmButtonColor: "#2e4a6c",
            cancelButtonColor: "#6c757d",
            input: "text",
            inputLabel:
                "Escribe la palabra 'eliminar', para confirmar esta operación.",
            inputAttributes: {
                autocapitalize: "off",
            },
        });
        if (result.value === "eliminar") {
            dispatch(startUILoading());

            const resp = await Call(
                `students/family/destroy/${familyId}`,
                "DELETE"
            );
            if (resp.success) {
                Swal.fire({
                    title: "Éxito",
                    text: "Eliminado con Éxito.",
                    icon: "success",
                });

                dispatch(startGetStudentByIdWithoutNavigate(studentNumber));
            } else {
                errorHandler(resp);
            }
        }

        dispatch(stopUILoading());
    };
};

export const startAddStudentNote = (
    values,
    academic_record,
    setErrors = null
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await CallWithFormData(
            "students/academic_record/notes",
            "PUT",
            values
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
            });
            dispatch(startGetStudentById(academic_record.student_number));
            dispatch(startStudentCloseForm());
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startChangeAdmisionCycle = (values) => {
    return async (dispatch, getState) => {
        dispatch(startUILoading());

        const resp = await Call(`operators/cycles/change`, "POST", values);

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Modificación exitosa.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 1000,
            });

            dispatch(updateInscriptionCycleId(resp.success));
        }

        dispatch(stopUILoading());
    };
};

export const startShowStudensOriginSchool = ({
    page = 1,
    query = "",
    number_rows = 25,
    inscription_cycle_id = null,
    schools_of_origins_id = null,
    schools_of_origins_type = null,
    from = null,
    to = null,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("page", page);
        params.set("q", query);
        params.set("per_page", number_rows);

        if (inscription_cycle_id) {
            params.set(
                "inscription_cycle_id",
                inscription_cycle_id.map((cycle) => cycle.value).join(",")
            );
        }
        if (schools_of_origins_id) {
            params.set(
                "schools_of_origins_id",
                schools_of_origins_id.map((school) => school.value).join(",")
            );
        }
        if (schools_of_origins_type) {
            params.set(
                "schools_of_origins_type",
                schools_of_origins_type.map((school) => school.value).join(",")
            );
        }
        if (from) {
            params.set("from", from);
        }
        if (to) {
            params.set("to", to);
        }

        const resp = await Call(
            `students/school-of-origin/show`,
            "GET",
            params.toString()
        );
        if (resp.success) {
            dispatch(startPaginationSchoolsOfOrgin(resp.success));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startExportStudensOriginSchool = ({
    inscription_cycle_id = null,
    schools_of_origins_id = null,
    schools_of_origins_type = null,
    from = null,
    to = null,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();
        params.set("export", 1);

        if (inscription_cycle_id) {
            params.set(
                "inscription_cycle_id",
                inscription_cycle_id.map((cycle) => cycle.value).join(",")
            );
        }
        if (schools_of_origins_id) {
            params.set(
                "schools_of_origins_id",
                schools_of_origins_id.map((school) => school.value).join(",")
            );
        }
        if (schools_of_origins_type) {
            params.set(
                "schools_of_origins_type",
                schools_of_origins_type.map((school) => school.value).join(",")
            );
        }
        if (from) {
            params.set("from", from);
        }
        if (to) {
            params.set("to", to);
        }

        const resp = await CallWithFormDataFile(
            `students/school-of-origin/show`,
            "GET",
            params.toString()
        );
        downloadBlob(resp, "Reporte de escuelas de procedencia");

        dispatch(stopUILoading());
    };
};

export const startShowCondensedStudensOriginSchool = ({
    inscription_cycle_id = null,
    schools_of_origins_id = null,
    schools_of_origins_type = null,
    from = null,
    to = null,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("isCondensed", 1);

        if (inscription_cycle_id) {
            params.set(
                "inscription_cycle_id",
                inscription_cycle_id.map((cycle) => cycle.value).join(",")
            );
        }
        if (schools_of_origins_id) {
            params.set(
                "schools_of_origins_id",
                schools_of_origins_id.map((school) => school.value).join(",")
            );
        }
        if (schools_of_origins_type) {
            params.set(
                "schools_of_origins_type",
                schools_of_origins_type.map((school) => school.value).join(",")
            );
        }
        if (from) {
            params.set("from", from);
        }
        if (to) {
            params.set("to", to);
        }

        const resp = await Call(
            `students/school-of-origin/show`,
            "GET",
            params.toString()
        );
        if (resp.success) {
            dispatch(startCondensedSchoolsOfOrigin(resp.success));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startShowCycleStudentsReport = (values) => {
    const { cycles, status, curriculum_ids, coordination_ids, degree } = values;

    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("cycles", `${cycles.year}-${cycles.sub_cycle}`);
        if (status.degree) {
            params.set("degree", degree);
        }
        if (status.length) {
            params.set("status", status.map((item) => item.value).join(","));
        }
        if (curriculum_ids.length) {
            params.set(
                "curriculum_ids",
                curriculum_ids.map((item) => item.value).join(",")
            );
        }
        if (coordination_ids.length) {
            params.set(
                "coordination_ids",
                coordination_ids.map((item) => item.value).join(",")
            );
        }

        const resp = await Call(
            "operators/reports/academic-records/by/cycle",
            "GET",
            params.toString()
        );

        if (resp.success) {
            dispatch(setCycleStudentsReport(resp.success));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startShowCycleStudentsReportExport = (values) => {
    const { cycles, status, curriculum_ids, coordination_ids } = values;

    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("cycles", `${cycles.year}-${cycles.sub_cycle}`);
        if (status.length) {
            params.set("status", status.map((item) => item.value).join(","));
        }
        if (curriculum_ids.length) {
            params.set(
                "curriculum_ids",
                curriculum_ids.map((item) => item.value).join(",")
            );
        }
        if (coordination_ids.length) {
            params.set(
                "coordination_ids",
                coordination_ids.map((item) => item.value).join(",")
            );
        }
        params.set("export", 1);

        const resp = await CallWithFormDataFile(
            "operators/reports/academic-records/by/cycle",
            "GET",
            params.toString()
        );
        downloadBlob(resp, "Reporte de alumnos por ciclo");

        dispatch(stopUILoading());
    };
};

//*Types
export const startStudentSetActive = (student) => ({
    type: types.studentsActive,
    payload: student,
});

export const startStudentUnsetActive = () => ({
    type: types.studentsUnsetActive,
});

export const startStudentOpenForm = () => ({
    type: types.studentsOpenForm,
});

export const startStudentCloseForm = () => ({
    type: types.studentsCloseForm,
});

export const startStudentOpenFormTags = () => ({
    type: types.studentsOpenFormTags,
});

export const startStudentCloseFormTags = () => ({
    type: types.studentsCloseFormTags,
});
export const startStudentSetTags = (student) => ({
    type: types.studentsTags,
    payload: student,
});
export const setStudentsGroupsList = (list) => ({
    type: types.studentsGroupsList,
    payload: list,
});

const show = (data) => ({
    type: types.studentsShow,
    payload: data,
});

const showStudentOnly = (data) => ({
    type: types.showStudentOnly,
    payload: data,
});

export const UpdateCycles = (data) => ({
    type: types.studentCyclesForceView,
    payload: data,
});

export const updateInscriptionCycleId = (data) => ({
    type: types.studentCycleChange,
    payload: data,
});

export const setGroupsByStudent = (data) => ({
    type: types.studentGroups,
    payload: data,
});

export const clearGroupsByStudent = () => ({
    type: types.studentClearGroups,
});

export const startPaginationSchoolsOfOrgin = (data) => ({
    type: types.studentsSchoolsOfOrigin,
    payload: data,
});

export const startCondensedSchoolsOfOrigin = (data) => ({
    type: types.studentsCondensedSchoolsOfOrigin,
    payload: data,
});

export const setCycleStudentsReport = (data) => ({
    type: types.studentsSetCycleStudentsReport,
    payload: data,
});

export const clearCycleStudentsReport = () => ({
    type: types.studentsClearCycleStudentsReport,
});

export const startBlockStudent = (data) => ({
    type: types.studentsBlock,
    payload: !!data,
});

export const startSetActiveCycle = (data) => ({
    type: types.studentsSetActiveCycle,
    payload: data,
});
