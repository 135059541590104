import React, { useEffect } from "react";
import { IconApp } from "../../../IconApp";
import { NavLinkApp } from "../../../NavLinkApp";
import { useHasPermission } from "../../../../hooks/useHasPermission";
import { useSelector } from "react-redux";

export const Modules_Students = () => {
    const { hasPermission } = useHasPermission();
    const { tiny_name } = useSelector((state) => state.config);
    const { user } = useSelector((state) => state.auth);

    return (
        <div>
            {hasPermission("SESSION_STUDENT") &&
                user.status !== "DEUDOR DE DOCUMENTOS" && (
                    <NavLinkApp
                        name="Estado de cuenta"
                        to="/students/billing/status"
                        icon={
                            <IconApp iconClassName="fa-solid fa-money-check-dollar c-sidebar-nav-icon" />
                        }
                    />
                )}

            {hasPermission("SESSION_STUDENT") &&
                user.status !== "DEUDOR DE DOCUMENTOS" && (
                    <NavLinkApp
                        name="Servicios escolares"
                        to="/students/extra-payments/screen"
                        icon={
                            <IconApp iconClassName="fa-solid fa-basket-shopping  c-sidebar-nav-icon" />
                        }
                    />
                )}
            {hasPermission("SESSION_STUDENT") &&
                user.status !== "DEUDOR DE DOCUMENTOS" && (
                    <NavLinkApp
                        name="Mis calificaciones"
                        to="/students/grades"
                        icon={
                            <IconApp iconClassName="fa-solid fa-star  c-sidebar-nav-icon" />
                        }
                    />
                )}
            {/* {hasPermission("SESSION_STUDENT") &&
                user.status !== "DEUDOR DE DOCUMENTOS" && (
                    <NavLinkApp
                        name="Materias"
                        to="/students/subjects"
                        icon={
                            <IconApp iconClassName="fa-solid fa-books c-sidebar-nav-icon" />
                        }
                    />
                )} */}
            {tiny_name != "EAD" &&
                hasPermission("SESSION_STUDENT") &&
                user.status !== "DEUDOR DE DOCUMENTOS" && (
                    <NavLinkApp
                        name="Ver horarios"
                        to="/students/schedule/screen"
                        icon={
                            <IconApp iconClassName="fa-solid fa-calendar-days c-sidebar-nav-icon" />
                        }
                    />
                )}
            {/* {tiny_name != "EAD" &&
                hasPermission("SESSION_STUDENT") &&
                user.status !== "DEUDOR DE DOCUMENTOS" && (
                    <NavLinkApp
                        name="Asistencias y faltas"
                        to="/students/attendances"
                        icon={
                            <IconApp iconClassName="fa-solid fa-clipboard-list-check c-sidebar-nav-icon" />
                        }
                    />
                )} */}
            {hasPermission(["SESSION_STUDENT"]) &&
                user.status !== "DEUDOR DE DOCUMENTOS" && (
                    <NavLinkApp
                        name="Becas"
                        to="/students/scholarship-request/screen"
                        icon={
                            <IconApp iconClassName="fa-solid fa-badge-percent c-sidebar-nav-icon" />
                        }
                    />
                )}

            {(tiny_name !== "EAD" &&
                hasPermission(["SESSION_STUDENT"]) &&
                user.status !== "DEUDOR DE DOCUMENTOS") && (
                    <NavLinkApp
                        name="Servicio y prácticas"
                        to="/students/internship"
                        icon={
                            <IconApp iconClassName="fa-solid fa-regular fa-briefcase c-sidebar-nav-icon" />
                        }
                    />
                )}

            {hasPermission("SESSION_STUDENT") &&
                user.status !== "DEUDOR DE DOCUMENTOS" && (
                    <NavLinkApp
                        name="Historial de pagos"
                        to="/students/billing/payments-history"
                        icon={
                            <IconApp iconClassName="fa-solid fa-envelope-open-dollar  c-sidebar-nav-icon" />
                        }
                    />
                )}

            {tiny_name !== "EAD" && hasPermission("SESSION_STUDENT") && (
                <NavLinkApp
                    name="Carga de documentos"
                    to="/students/load-documents"
                    icon={
                        <IconApp iconClassName="fa-regular fa-folder-open  c-sidebar-nav-icon" />
                    }
                />
            )}

            {hasPermission("SESSION_STUDENT") && (
                <NavLinkApp
                    name="Aclaraciones"
                    to="/students/aclarations"
                    icon={
                        <IconApp iconClassName="fa-solid fa-message-exclamation  c-sidebar-nav-icon" />
                    }
                />
            )}
        </div>
    );
};
