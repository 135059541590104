import { createSlice } from "@reduxjs/toolkit";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import { Call } from "../../../helpers/fetch";
import { errorHandler } from "../../../helpers/errorHandler";

const initialState = {
    report: {
        report: [],
        cycle: null,
        curriculum: null,
        total_teachers: 0,
        total_grades_upload: 0,
        total_grades_without_upload: 0,
        total_grades_incomplete_upload: 0,
        total_grades: 0,
        total_teachers_without_upload: 0,
        total_teachers_complete_upload: 0,
        total_teachers_incomplete_upload: 0,
    },
};

export const gradesReportScreen = createSlice({
    name: "gradesReportScreen",
    initialState,
    reducers: {
        setReport: (state, action) => {
            state.report = action.payload;
        },
        restartReport: (state, action) => {
            state.report = initialState.report;
        },
    },
});

export const { setReport, restartReport } = gradesReportScreen.actions;

export default gradesReportScreen.reducer;

//*Actions
export const startGetReportGrades = (values) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const urlParams = new URLSearchParams();
        if (values?.cycle_id) {
            urlParams.set("cycle_id", values?.cycle_id);
        }
        if (values?.curriculum_ids?.length > 0) {
            urlParams.set(
                "curriculum_ids",
                values?.curriculum_ids?.map((ci) => ci?.value)?.join(",")
            );
        }
        if (values?.coordination_id) {
            urlParams.set("coordination_id", values?.coordination_id);
        }
        if (values?.period) {
            urlParams.set("period", values?.period);
        }
        const resp = await Call(
            `operators/students/grades/upload/report`,
            "GET",
            urlParams.toString()
        );

        if (resp.success) {
            dispatch(setReport(resp.success));
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};
